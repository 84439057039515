import React from 'react'
// import consulting from "../../Images/consulting.png"
const Consultingmain = ({ businessData }) => {
    return (
        <section className='industrialsolution_banner'>
            <div className='bgimage_'>
                <img src={businessData?.banner} className='img-fluid' alt='..' />
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> {businessData && businessData?.whiteHeading}<span> {businessData && businessData?.blueHeading}  </span> </h2>
                </div>

            </div>

        </section>
    )
}

export default Consultingmain
