import React from 'react'
// import expertiesdoman from "../../Images/expertiesdoman.jpg";
// import automativesoftwareengineer from "../../Images/automativesoftwareengineer.jpg";
// import comprehensivesolution from "../../Images/comprehensivesolution.jpg";
// import collboratepartnership from "../../Images/collboratepartnership.jpg";
// import driveninnovation from "../../Images/driveninnovation.jpg";
// import trailoredsavvallsolution from "../../Images/trailoredsavvallsolution.jpg";
import cygnuspriidegttsimg from "../../Images/cygnuspriidegttsimg.jpg"

const Automotivecontentmain = ({ automotiveData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            automotiveData && automotiveData?.content?.length !== 0 && automotiveData?.content?.map((automotiveItem, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text">{automotiveItem?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                      {
                        automotiveItem?.description
                      }
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_img">
                    <img src={automotiveItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>



      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className='choosedivflx'>
            <div className='choosedivleft'>
              <h4 className="hlthcaretetx_heading_text">Why Choose Cygnus Professionals?</h4>
              <p className="hlthcaretetx_para_text_new">
                <span> 	Industry Acumen : </span> Our team comprises seasoned professionals with extensive experience in the automotive sector, enabling us to provide insights and solutions that are both relevant and impactful.
              </p>

              <p className="hlthcaretetx_para_text_new">
                <span>	Client-Centric Focus :</span> We prioritize your aspirations and endeavor to forge enduring relationships. By gaining a deep understanding of your challenges, we devise strategies that drive meaningful success.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> 	Commitment to Quality :  </span>Our dedication to excellence ensures that every project undergoes rigorous quality assurance processes, delivering reliable and high-caliber software solutions.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> Proven Excellence : </span> With a robust history of supporting numerous automotive clients, we have consistently delivered projects on time and within budget, reflecting our commitment to excellence.
              </p>

            </div>
            <div className='choosedivright'>
              <div className='choosedivrightimgmain'>
                <img src={cygnuspriidegttsimg} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className=''>
            <h4 className="hlthcaretetx_heading_text">{automotiveData?.endHeading}</h4>
            <p className="hlthcaretetx_para_text">
              {
                automotiveData?.endDescription
              }
            </p>
          </div>
        </div>
      </section>

    </>
  )
}

export default Automotivecontentmain
