import React from 'react'
import "../../Css/Story.css";
import strydtls from "../../Images/strydtls.png"
const Storydetails = ({ aboutCygnusData }) => {
    const { title, heading, description, banner } = aboutCygnusData;
    return (
        <>
            <section className='storydetilsdivmain'>
                <div className='cus_container'>
                    <div className='stotyimgtextdivwrapper'>
                        <img src={banner} alt='...' className='bannerimagdspdoibbfkl' />
                        <div className='storydetailsdivspcjklp'>
                            <h4 className='srtyydetailstxtp'>{title}</h4>
                            <p className='storydetlas_txtp'>
                                {heading}
                            </p>
                            <p className='cygnusprofetxtopp'>
                                {description}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Storydetails
