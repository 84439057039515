import React, { useEffect, useState } from 'react'
import Automotivemain from './Automotivemain'
import Automotivecontent from './Automotivecontent'
import Automotivecontentmain from './Automotivecontentmain'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Automotiove = () => {
    const [automotiveData, setAutomotiveData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch Automotive Data<<<<
    const fetchAutomotiveData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("solution", "POST", {
            title: 'Automotive'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setAutomotiveData(res?.data)
        } else {
            setIsLoading(false);
            setAutomotiveData({});
        }
    }

    useEffect(() => {
        fetchAutomotiveData();
    }, []);
    return (
        <>
            {isLoading && <Loader />}
            <Automotivemain automotiveData={automotiveData} />
            <Automotivecontent automotiveData={automotiveData} />
            <Automotivecontentmain automotiveData={automotiveData} />
        </>
    )
}

export default Automotiove
