import React, { useEffect, useState } from 'react'
import Storydetails from './Storydetails'
import Missionvission from './Missionvission'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Storymain = () => {
  const [aboutCygnusData, setAboutCygnusData] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  // >>>>Fetch About Data<<<<
  const fetchAboutData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("aboutCygnus", "GET");
    if (res && res?.data) {
      setIsLoading(false)
      setAboutCygnusData(res?.data)
    } else {
      setIsLoading(false)
      setAboutCygnusData({})
    }
  }

  useEffect(() => {
    fetchAboutData()
  }, [])
  return (
    <>
      {isLoading && <Loader />}
      <Storydetails aboutCygnusData={aboutCygnusData} />
      <Missionvission aboutCygnusData={aboutCygnusData} />
    </>
  )
}

export default Storymain
