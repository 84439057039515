import React, { useEffect, useState } from 'react'
import Fullstackmain from './Fullstackmain'
import Fullstackcontent from './Fullstackcontent'
import Fullstackmaincontent from './Fullstackmaincontent'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Fullstack = () => {
  const [fullStackData, setFullStackData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch FullStack Data<<<<
    const fetchFullStackData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("services", "POST", {
            title: 'FullStack'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setFullStackData(res?.data)
        } else {
            setIsLoading(false);
            setFullStackData({});
        }
    }

    useEffect(() => {
      fetchFullStackData();
    }, []);
  return (
      <>
            {isLoading && <Loader />}
            <Fullstackmain fullStackData={fullStackData} />
            <Fullstackcontent fullStackData={fullStackData} />
            <Fullstackmaincontent fullStackData={fullStackData} />
      </>
  )
}

export default Fullstack
