import React, { useState } from 'react'
import carrerimg from "../../Images/Cygnus Pictures/Career page/carrerimg.jpg"
const Carreropertunity = ({ careerData }) => {
    const [fronenencllcl, setFronenencllcl] = useState(false);
    console.log('careerData: ', careerData);

    const { image } = careerData;
    const frontendclick = () => {
        setFronenencllcl(!fronenencllcl)
    }
    return (
        <>
            <section className='workmainsection'>
                <div className='cus_container'>
                    <div className='carrerfrmdivmaibdivflx'>
                        <div className='carrerfrmdivmaib'>
                            <h4 className="carrertxtteamhhh"> Career Opertunities</h4>
                            {
                                careerData?.developerData?.length !== 0 && careerData?.developerData?.map((developer, index) => (
                                    <div className={fronenencllcl ? "frtrwdivnainkkk chng" : "frtrwdivnainkkk"} key={index}>
                                        <p className='froimyttstxtarrowdivttx' onClick={frontendclick}>{developer?.heading}
                                            <span>
                                                <i class="fa-solid fa-chevron-down"></i>
                                            </span>
                                        </p>
                                        {
                                            fronenencllcl && (<div className="">
                                                <ul className="drpediowocliclkul">
                                                    {
                                                        developer?.description?.split(', ')?.map((workMode, index) => (
                                                            <li className="drpediowocliclkul" key={index}>
                                                                {workMode}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>)
                                        }
                                    </div>
                                ))
                            }
                            {/* 
                            <div className={fronenencllcl ? "frtrwdivnainkkk chng" : "frtrwdivnainkkk"}>
                                <p className='froimyttstxtarrowdivttx' onClick={frontendclick}>Front End Developer
                                    <span>
                                        <i class="fa-solid fa-chevron-down"></i>
                                    </span>

                                </p>

                                {fronenencllcl && <div className="">
                                    <ul className="drpediowocliclkul">
                                        <li className="drpediowocliclkul">
                                            Remote
                                        </li>
                                        <li className="drpediowocliclkul">
                                            Work From Office
                                        </li>
                                        <li className="drpediowocliclkul">
                                            Work Frome Home
                                        </li>
                                        <li className="drpediowocliclkul">
                                            Hybrid
                                        </li>
                                    </ul>
                                </div>}

                            </div> */}



                            <button className='seemoremnbbtgbtn'>
                                See More
                            </button>


                        </div>


                        <div className='carrerimgdivmain'>
                            <div className='carrerimgdivmainimg'>
                                <img src={image} alt='...' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Carreropertunity
