import React from 'react'
import CyberSecuritybanner from "../../Images/CyberSecuritybanner.jpg"
const Cybersecuritymain = ({ cyberSecData }) => {
  const { whiteHeading, blueHeading, banner } = cyberSecData;
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'>{whiteHeading}<span className='security-span'>{blueHeading}</span></h2>
        </div>
      </section>
    </>

  )
}

export default Cybersecuritymain
