import React from 'react'
// import automotivebanner from "../../Images/automotivebanner.jpg"
const Automotivemain = ({ automotiveData }) => {
  return (
    <section className='industrialsolution_banner'>
      <div className='bgimage_'>
        <img src={automotiveData?.banner} className='img-fluid' alt='..' />
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {automotiveData?.whiteHeading}  </h2>
        </div>
      </div>
    </section>
  )
}

export default Automotivemain
