import React from 'react'
// import EXCUTIVESEARCH from "../../Images/EXCUTIVESEARCH.jpg"
const Excutivesearchmain = ({ executiveSearchData }) => {
    return (
        <>
            <section className='industrialsolution_banner'>
                <div className='bgimage_'>
                    <img src={executiveSearchData && executiveSearchData?.banner} className='img-fluid' alt='..' />
                </div>
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> {executiveSearchData && executiveSearchData?.whiteHeading} <span> {executiveSearchData && executiveSearchData?.blueHeading} </span> </h2>
                </div>
            </section>
        </>
    )
}

export default Excutivesearchmain
