import React from 'react'

const Generativeaicontent = ({ frameWorkData }) => {
  return (
    <section className='SectorWeServer_sec'>
      <div className='cus_container'>
        <h2 className='sec_heading'>{frameWorkData?.[0]?.mainHeading}</h2>

        <p className='hlthcaretetx_para_text'>
          {frameWorkData?.[0]?.description}
        </p>

      </div>
    </section>
  )
}

export default Generativeaicontent
