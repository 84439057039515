import React from 'react'
// import Machinelearning from "../../Images/Machinelearning.png";
// import dataanalytics from "../../Images/dataanalytics.png";
// import DIGITALANALYTICS from "../../Images/DIGITALANALYTICS.jpg";
// import BusinessIntelligence from "../../Images/BusinessIntelligence.jpg";
// import cuuut from "../../Images/cuuut.jpeg"
const Digitalandanalyticsmaincontent = ({ digitalAnalyticsData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            digitalAnalyticsData && digitalAnalyticsData?.content?.length !== 0 && digitalAnalyticsData?.content?.map((digitalItem, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text">{digitalItem?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                      {digitalItem?.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_imgmainalada">
                    <img src={digitalItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default Digitalandanalyticsmaincontent
