import React, { useEffect, useState } from 'react'
import Pharmacontent from './Pharmacontent'
import Pharmamain from './Pharmamain'
import Pharmacontentmain from './Pharmacontentmain'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Pharma = () => {
    const [pharmaData, setPharmaData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch Pharma Data<<<<
    const fetchPharmaData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("solution", "POST", {
            title: 'PHARMA'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setPharmaData(res?.data)
        } else {
            setIsLoading(false);
            setPharmaData({});
        }
    }

    useEffect(() => {
        fetchPharmaData();
    }, []);
    return (
        <>
            {isLoading && <Loader />}
            <Pharmamain pharmaData={pharmaData} />
            <Pharmacontent pharmaData={pharmaData} />
            <Pharmacontentmain pharmaData={pharmaData} />
        </>
    )
}

export default Pharma
