import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import MANISH from "../../Images/MANISH.png";
import RUSLAN from "../../Images/RUSLAN.png"
import MILAN_KUCERA from "../../Images/MILAN_KUCERA.png"
import JAMES_PATTERSO from "../../Images/JAMES_PATTERSO.png";
import SONYA_SELINSKA from "../../Images/SONYA_SELINSKA.png";
import MELADEE_CONNER from "../../Images/MELADEE_CONNER.png"
const Advisioryboard = ({ ourTeamData }) => {
    const { AdvisoryBoard } = ourTeamData;
    return (
        <>
            <section className='expertteammainsection'>
                <div className='cus_container'>
                    <div className=''>
                        <h4 className='experteamhhh'>
                            Advisory Board
                        </h4>
                    </div>
                    <div className=''>

                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },
                                991: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                                1199: {
                                    slidesPerView: 3,
                                },
                                1400: {
                                    slidesPerView: 3,
                                },
                                2000: {
                                    slidesPerView: 3,
                                },
                            }}
                            className='mySwiper'
                        >
                            {/* <SwiperSlide >
                                <div className="experteamimimgadvisorimgdiv">
                                    <div className='experteamimimgadvisorimg'>
                                        <img src={MANISH} alt='...' />
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='ctgsttetxtmnertxtpp'>MANISH SOMASHEKAR</p>
                                    
                                </div>
                            </SwiperSlide> */}
                            
                            {AdvisoryBoard && AdvisoryBoard?.length !== 0 &&
                                AdvisoryBoard?.map((ele) => {
                                    return (
                                        <SwiperSlide >
                                            <div key={ele?._id} className="experteamimimgadvisorimgdiv">
                                                <div className="experteamimimgadvisorimg">
                                                    <img src={ele?.image} alt='...' />
                                                </div>
                                            </div>
                                            <div className=''>
                                                <p className='ctgsttetxtmnertxtpp'>{ele?.name}</p >
                                                {/* <p className='dattatstxtpp'>Data engineer</p> */}
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                            {/* <SwiperSlide >
                                <div className="experteamimimgadvisorimgdiv">
                                    <div className="experteamimimgadvisorimg">
                                        <img src={MILAN_KUCERA} alt='...' />
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='ctgsttetxtmnertxtpp'>MILAN KUCERA</p>
                                </div>
                            </SwiperSlide> */}
                            {/* <SwiperSlide >
                                <div className="experteamimimgadvisorimgdiv">
                                    <div className='experteamimimgadvisorimg'>
                                        <img src={JAMES_PATTERSO} alt='...' />
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='ctgsttetxtmnertxtpp'>JAMES PATTERSON JR</p>
                                </div>
                            </SwiperSlide> */}
                            {/* <SwiperSlide >
                                <div className="experteamimimgadvisorimgdiv">
                                    <div className="experteamimimgadvisorimg">
                                        <img src={SONYA_SELINSKA} alt='...' />
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='ctgsttetxtmnertxtpp'>SONYA SELINSKA</p>
                                  
                                </div>
                            </SwiperSlide> */}
                            {/* <SwiperSlide >
                                <div className="experteamimimgadvisorimgdiv">
                                    <div className="experteamimimgadvisorimg">
                                        <img src={MELADEE_CONNER} alt='...' />
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='ctgsttetxtmnertxtpp'>MELADEE CONNER</p>
                                    
                                </div>
                            </SwiperSlide> */}
                            {/* <SwiperSlide >
                                <div className="experteamimimgadvisorimgdiv">
                                    <div className="experteamimimgadvisorimg">
                                        <img src={experteam3} alt='...' />
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='ctgsttetxtmnertxtpp'>Chris Mezy</p>
                                    <p className='dattatstxtpp'>Data engineer</p>
                                </div>
                            </SwiperSlide> */}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Advisioryboard
