import React from 'react'

const Fullstackcontent = ({fullStackData}) => {
  return (
     <>
        <section className='SectorWeServer_sec'>
        <div className='cus_container'>
            <h2 className='sec_heading'>{fullStackData && fullStackData?.mainHeading}</h2>
          <p className='hlthcaretetx_para_text'>
          {fullStackData && fullStackData?.description}
          </p>

          <h2 className='ggpappadinbtm'>
          Our offerings include : 
          </h2>


        </div>
      </section>
     </>
  )
}

export default Fullstackcontent
