import React from 'react'
import engineering from "../../Images/engineering.jpeg"
const Engineeringmain = ({ engineeringServiceData }) => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={engineeringServiceData && engineeringServiceData?.banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {engineeringServiceData && engineeringServiceData?.whiteHeading} <span> {engineeringServiceData && engineeringServiceData?.blueHeading}  </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Engineeringmain
