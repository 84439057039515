import React from 'react'
// import mspbanner from "../../Images/mspbanner.jpg"
const Mspprogrammain = ({ mspData }) => {
    return (
        <>
            <section className='industrialsolution_banner'>
                <div className='bgimage_'>
                    <img src={mspData && mspData?.banner} className='img-fluid' alt='..' />
                </div>
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> {mspData && mspData?.whiteHeading} <span> {mspData && mspData?.blueHeading} </span> </h2>
                </div>
            </section>
        </>
    )
}

export default Mspprogrammain
