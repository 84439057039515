import React from 'react'

const Cloudtechnologiescontent = ({ cloudTechnologiesData }) => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          <h2 className='sec_heading'>{cloudTechnologiesData && cloudTechnologiesData?.mainHeading}</h2>
          <p className='hlthcaretetx_para_text'>
            {cloudTechnologiesData && cloudTechnologiesData?.description}
          </p>

          <h2 className='ggpappadinbtm'>
            Our services include :
          </h2>


        </div>
      </section>
    </>
  )
}

export default Cloudtechnologiescontent
