import React from 'react'

const Cybersecuritycontent = ({ cyberSecData }) => {
  const { mainHeading, description } = cyberSecData;
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          <h2 className='sec_heading'>{mainHeading}</h2>
          <p className='hlthcaretetx_para_text'>
            {description}
          </p>
          <h4 className='ggpappadinbtm'>
            Key Services :
          </h4>

        </div>
      </section>
    </>
  )
}

export default Cybersecuritycontent
