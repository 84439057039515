import React from 'react'
// import ongoingsarch from "../../Images/ongoingsarch.jpg";
import StrategicTalent from "../../Images/StrategicTalent.jpg";
// import discover from "../../Images/discover.jpg";
// import RigorousEvaluation from "../../Images/RigorousEvaluation.jpg";
// import Confidential from "../../Images/Confidential.jpg"
const Excutivesearchcontentmain = ({ executiveSearchData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            executiveSearchData && executiveSearchData?.content?.length !== 0 && executiveSearchData?.content?.map((executiveItem, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text"> {executiveItem?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                      {executiveItem?.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_imgmainalada">
                    <img src={executiveItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default Excutivesearchcontentmain
