import React from 'react'
// import { Link } from 'react-router-dom'

const Staffingcontent = ({staffingServicesData}) => {
    
    return (
        <section className='SectorWeServer_sec'>
            <div className='cus_container'>
                <h2 className='sec_heading'>{staffingServicesData && staffingServicesData?.mainHeading} </h2>



                <p className='hlthcaretetx_para_text'>
                {staffingServicesData && staffingServicesData?.description}
                </p>
                {/* <p className='hlthcaretetx_para_text'>
                {staffingServicesData && staffingServicesData?.description.split(' ')[staffingServicesData?.description.split(' ').length - 1]}
                </p> */}
{/* 
                <ul className='setydyydhshjugj'> */}
                   {/* <p className='akjjdjyuhg'> All Staffing Services are : </p> */}
                    {/* <li className='setydyydhshjugjli'>
                        <Link className='setydyydhshjugjliaa' to="/">Contigent Service</Link>
                    </li> */}
                    {/* <li className='setydyydhshjugjli'>
                        <Link className='setydyydhshjugjliaa' to="/">Executive Search</Link>
                    </li>
                    <li className='setydyydhshjugjli'>
                        <Link className='setydyydhshjugjliaa' to="/">Engineering Service</Link>
                    </li> */}
                {/* </ul> */}

            </div>
        </section>


    )
}

export default Staffingcontent
