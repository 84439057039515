import React from 'react'
import pharramcompliendcebanner from "../../Images/pharramcompliendcebanner.jpg"
const Pharmacompliencemain = ({ pharmaComplienceData }) => {
  const { whiteHeading, blueHeading, banner } = pharmaComplienceData;
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {whiteHeading} <span className='compliance-span'>{blueHeading}</span></h2>
        </div>
      </section>
    </>
  )
}

export default Pharmacompliencemain
