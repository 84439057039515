import React from 'react'

const Pharmacompliencecontent = ({ pharmaComplienceData }) => {
  const { mainHeading, description } = pharmaComplienceData;
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          <h2 className='sec_heading'>{mainHeading}</h2>
          <p className='hlthcaretetx_para_text'>
            {description}
          </p>
          <p className='hlthcaretetx_para_text'>
            Our comprehensive expertise spans :
          </p>

        </div>
      </section>
    </>
  )
}

export default Pharmacompliencecontent
