import React from 'react'
// import legacy from "../../Images/legacy.png";
const Legacymodernizationmaincontent = ({ legacyData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            legacyData && legacyData?.content?.length !== 0 && legacyData?.content?.map((legacyItem, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">

                    <p className="hlthcaretetx_para_text">
                      {legacyItem.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_img">
                    <img src={legacyItem.image} alt="..." />
                  </div>
                </div>
              </div>

            ))
          }

        </div>
      </section>
    </>
  )
}

export default Legacymodernizationmaincontent
