import React from 'react'
import mision from "../../Images/Cygnus Pictures/Our story page/mision.jpg";
import vision from "../../Images/Cygnus Pictures/Our story page/vision.jpg";
import mmisiiontemaimg from "../../Images/Cygnus Pictures/Our story page/clark-tibbs-oqStl2L5oxI-unsplash.jpg"
const Missionvission = ({ aboutCygnusData }) => {
    const { missionVision, bottomImage } = aboutCygnusData;
    console.log("aboutCygnusData12", aboutCygnusData)
    return (
        <section className='missionvissionmain'>
            <div className='cus_container'>
                <div className=''>
                    <h4 className='missionvissontxtpp'> Mission and Vision</h4>
                </div>

                <div className='miisoonvissiondiv'>
                    {/* Mission */}
                    <div className='miisondiv'>
                        <div className='miisondivbrdr'>
                            <div className='imhmisionvison'>
                                <img src={missionVision?.[0]?.image} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='misisontxtpp'>{missionVision?.[0]?.title}</h4>
                            </div>

                            <div className='misisontxtppmainodiv'>
                                <p className='misisontxtppmaino'>
                                    {missionVision?.[0]?.description}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Vission */}
                    <div className='visiondiv'>
                        <div className='visondivbrdr'>
                            <div className='imhmisionvison'>
                                <img src={missionVision?.[1]?.image} alt='...' />
                            </div>
                            <div className=''>
                                <h4 className='misisontxtpp'>{missionVision?.[1]?.title}</h4>
                            </div>
                            <div className='misisontxtppmainodiv'>
                                <p className='misisontxtppmaino'>
                                {missionVision?.[1]?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='imhagmiidllestpittstmaion'>
                    <img src={bottomImage} alt='...' />
                </div>
            </div>
        </section>
    )
}

export default Missionvission
