import React, { useEffect, useState } from 'react'
import Aimlmain from './Aimlmain'
import Aimlcontent from './Aimlcontent'
import Aimlmaincontent from './Aimlmaincontent'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Aiml = () => {
    const [aiMlData, setAIMLData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch AI/ML Data<<<<
    const fetchAIMLData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("services", "POST", {
            title: 'AI/ML'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setAIMLData(res?.data)
        } else {
            setIsLoading(false);
            setAIMLData({});
        }
    }

    useEffect(() => {
        fetchAIMLData();
    }, []);
    return (
        <>
            {isLoading && <Loader />}
            <Aimlmain aiMlData={aiMlData} />
            <Aimlcontent aiMlData={aiMlData} />
            <Aimlmaincontent aiMlData={aiMlData} />
        </>
    )
}

export default Aiml
