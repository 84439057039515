import React from 'react'
// import pahhahh from "../../Images/pahhahh.jpg"
const Pharmamain = ({ pharmaData }) => {
    return (
        <section className='industrialsolution_banner'>
            <div className='bgimage_'>
                <img src={pharmaData?.banner} className='img-fluid' alt='..' />
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> {pharmaData?.whiteHeading} </h2>
                </div>

            </div>

        </section>
    )
}

export default Pharmamain
