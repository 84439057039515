import React from 'react'
// import AutomationEfficiency from "../../Images/AutomationEfficiency.jpg";
import CLOUDTECHNOLOGIESnwe from "../../Images/CLOUDTECHNOLOGIESnwe.jpg";
// import CLOUDTECHNOLOGIES from "../../Images/CLOUDTECHNOLOGIES.jpg";
// import BusinessIntelligence from "../../Images/BusinessIntelligence.jpg"
const Cloudtechnologiesmaincontent = ({ cloudTechnologiesData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            cloudTechnologiesData && cloudTechnologiesData?.content?.length !== 0 && cloudTechnologiesData?.content?.map((cloudItem, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text">{cloudItem?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                    {cloudItem?.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_imgmainalada">
                    <img src={cloudItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default Cloudtechnologiesmaincontent
