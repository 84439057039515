import React from 'react'
import Optimized from "../../Images/Optimized.jpg";
import EnhancedContentCreation from "../../Images/EnhancedContentCreation.jpg"
import advabdataanlytixcs from "../../Images/advabdataanlytixcs.jpg";
import AcceleratedDesign from "../../Images/AcceleratedDesign.jpg";
import Personalized from "../../Images/Personalized.jpg"
const Generativecontentmain = ({ frameWorkData }) => {

    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">

                    {frameWorkData?.[0]?.content && frameWorkData?.[0]?.content?.length !== 0 &&
                        frameWorkData?.[0]?.content?.map((ele) => {
                            return (
                                <div key={ele?._id} className="healthcares_divnewadd">
                                    <div className="healthcares_div_left">
                                        <div className="">
                                            <h4 className="hlthcaretetx_heading_text">{ele?.dataHeading}</h4>
                                            <p className="hlthcaretetx_para_text">
                                                {ele?.dataDescription}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="healthcares_div_right">
                                        <div className="healthcares_div_imgmainalada">
                                            <img src={ele?.dataImage} alt="..." />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/* <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Advanced Data Analysis</h4>
                                <p className="hlthcaretetx_para_text">
                                    Uncover valuable insights from extensive datasets to inform strategic decision-making.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={advabdataanlytixcs} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Accelerated Design and Prototyping</h4>
                                <p className="hlthcaretetx_para_text">
                                    Streamline development processes with AI-driven design and prototyping.
                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={AcceleratedDesign} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">Personalized User Experiences</h4>
                                <p className="hlthcaretetx_para_text">
                                    Deliver tailored experiences that enhance user engagement and satisfaction.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={Personalized} alt="..." />
                            </div>
                        </div>
                    </div>

                    <div className="healthcares_divnewadd">
                        <div className="healthcares_div_left">
                            <div className="">
                                <h4 className="hlthcaretetx_heading_text">	Optimized Operational Efficiency</h4>
                                <p className="hlthcaretetx_para_text">
                                    Automate tasks, streamline workflows, and improve resource utilization.

                                </p>
                            </div>
                        </div>
                        <div className="healthcares_div_right">
                            <div className="healthcares_div_imgmainalada">
                                <img src={Optimized} alt="..." />
                            </div>
                        </div>
                    </div> */}


                </div>
            </section >

            <section className="softwaresevices_main">
                <div className="cus_container">
                    <div>
                        <p className="hlthcaretetx_para_text">
                            {frameWorkData?.[0]?.endDescription}
                        </p>
                    </div>
                </div>
            </section>

        </>




    )
}

export default Generativecontentmain
