import React, { useEffect, useState } from 'react'
import Staffingmain from './Staffingmain'
import Staffingcontent from './Staffingcontent'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Staffingservice = () => {
    const [staffingServicesData, setStaffingServicesData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch Msp Data<<<<
    const fetchMspData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("services", "POST", {
            title: 'Staffing Services'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setStaffingServicesData(res?.data)
        } else {
            setIsLoading(false);
            setStaffingServicesData({});
        }
    }

    useEffect(() => {
        fetchMspData();
    }, []);
    return (
        <>
            {isLoading && <Loader />}
            <Staffingmain staffingServicesData={staffingServicesData} />
            <Staffingcontent staffingServicesData={staffingServicesData} />
        </>
    )
}

export default Staffingservice
