import React from 'react'

const Mobileappscontent = ({ mobileAppsData }) => {
    return (
        <>
            <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>{mobileAppsData && mobileAppsData?.mainHeading}</h2>
                    <p className='hlthcaretetx_para_text'>
                        {mobileAppsData && mobileAppsData?.description}
                    </p>
                    <p className='hlthcaretetx_para_text'>
                        At Cygnus, we take a holistic approach to app development, encompassing everything from initial concept to continuous support. Our focus is on delivering top-notch apps that resonate with users and drive tangible results. Here's how we pave the way for your app's success
                    </p>

                </div>
            </section>
        </>
    )
}

export default Mobileappscontent
