import React from 'react'

const Automotivecontent = ({ automotiveData }) => {
  return (
    <section className='SectorWeServer_sec'>
      <div className='cus_container'>
        <p className='hlthcaretetx_para_text'>
          {automotiveData && automotiveData?.description}
        </p>
      </div>
    </section>
  )
}

export default Automotivecontent
