import React from 'react'
// import CLOUDTECHNOLOGIESnweww from "../../Images/CLOUDTECHNOLOGIESnweww.jpg"
const Cloudtechnologiesmain = ({ cloudTechnologiesData }) => {
  return (
    <section className='industrialsolution_banner'>
      <div className='bgimage_'>
        <img src={cloudTechnologiesData && cloudTechnologiesData?.banner} className='img-fluid' alt='..' />
      </div>
      <div className='banner_wrap'>
        <h2 className='sec_headingbannertset'> {cloudTechnologiesData && cloudTechnologiesData?.whiteHeading} <span> {cloudTechnologiesData && cloudTechnologiesData?.blueHeading}  </span> </h2>
      </div>
    </section>
  )
}

export default Cloudtechnologiesmain
