import React from 'react'
import gurudasnew from "../../Images/gurudasnew.png";
import experteam2 from "../../Images/experteam2.png";
import experteam3 from "../../Images/experteam3.png";
import experteam4 from "../../Images/experteam4.png";
import experteam5 from "../../Images/experteam5.png";
import experteam6 from "../../Images/experteam6.png";
import experteam7 from "../../Images/experteam7.png";
import experteam8 from "../../Images/experteam8.png";
import arddaduop from "../../Images/arddaduop.png"
const ExpertTeam = ({ ourTeamData }) => {

    const { expertData } = ourTeamData;
    return (
        <section className='expertteammainsection'>
            <div className='cus_container'>
                <div className=''>
                    <h4 className='experteamhhh'>
                        Meet our team of experts in tech and marketing
                    </h4>
                </div>
                <div className='experteamdivbg_main'>


                    {expertData && expertData?.length !== 0 &&
                        expertData?.map((ele) => {
                            return (
                                <div key={ele?._id} className='experteamdivbg'>

                                    <img src={ele?.image} alt='...' />

                                    <div className='descriptiondivmainbox'>
                                        <p className='desigbtetxttppyyuuyp'>
                                            {ele?.description}
                                            {/* Dhanashri Nair specializes in the Business Development, Client and Employee Relations, Building Recruitment Strategies, Merger, and Acquisitions. Focusing on innovation and delivering the right talent to cater the client demands in the cloud and big data solutions are her expertise in various industry domains. She is passionate about meeting commitments and highly values all organizational relationships */}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/* <div className='experteamdivbg'>
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam4} alt='...' />
                            </div>
                        </div>
                    </div>

                    <div className='experteamdivbg' >
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam3} alt='...' />
                            </div>
                        </div>
                    </div>

                    
                    <div className='experteamdivbg'>
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam2} alt='...' />
                            </div>
                        </div>
                    </div>

                    <div className='experteamdivbg'>
                        <div className='experteamdivbgdiv'>
                            <div className='experteamdivbgdivtop'>
                                <p className='experttemname'>Chris Mezy</p>
                                <p className='experttemnamedesg'>Data enginner</p>
                            </div>

                            <div className='experteamdivbgdivbottom'>
                                <img src={experteam1} alt='...' />
                            </div>
                        </div>
                    </div> */}





                </div>

            </div>
        </section >
    )
}

export default ExpertTeam
