import React from 'react'
// import legacybanner1 from "../../Images/legacybannerbanner1.jpg"
const Legacymodernizationmain = ({ legacyData }) => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={legacyData && legacyData?.banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {legacyData && legacyData?.whiteHeading} <span> {legacyData && legacyData?.blueHeading} </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Legacymodernizationmain
