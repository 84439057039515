import React, { useEffect, useState } from 'react'
import Sapandoraclemain from './Sapandoraclemain'
import Sapandoraclecontent from './Sapandoraclecontent'
import Sapandoraclemaincontent from './Sapandoraclemaincontent'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Sapandoracle = () => {
  const [sapAndOracleData, setSapAndOracleData] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  // >>>>Fetch SAP AND Oracle Data<<<<
  const fetchSapAndOracleData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("services", "POST", {
      title: 'SAP/Oracle'
    });
    if (res && res?.status) {
      setIsLoading(false)
      setSapAndOracleData(res?.data)
    } else {
      setIsLoading(false);
      setSapAndOracleData({});
    }
  }

  useEffect(() => {
    fetchSapAndOracleData();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Sapandoraclemain sapAndOracleData={sapAndOracleData} />
      <Sapandoraclecontent sapAndOracleData={sapAndOracleData} />
      <Sapandoraclemaincontent sapAndOracleData={sapAndOracleData} />
    </>
  )
}

export default Sapandoracle
