import React from 'react'
// import retails from "../../Images/retails.jpg"
const Retailmain = ({retailData}) => {
  return (
    <section className='industrialsolution_banner'>
      <div className='bgimage_'>
        <img src={retailData?.banner} className='img-fluid' alt='..' />
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {retailData?.whiteHeading}  </h2>
        </div>

      </div>

    </section>
  )
}

export default Retailmain
