import React from 'react'
import workwith from "../../Images/workwith.png"
const Whywork = ({ careerData }) => {
    const { workWithUs } = careerData;
    return (
        <>
            <section className=''>
                <div className='cus_container'>
                    <div className=''>
                        <h4 className='worlttstxthhh'>Why Work With Us ?</h4>
                    </div>

                    <div className='whyworkdivmaindivflx'>
                        {workWithUs && workWithUs?.length !== 0 &&
                            workWithUs?.map((ele) => {
                                return (
                                    <div key={ele?._id} className='whyworkdivmain'>
                                        <div className='whyworkdivmainimg'>
                                            <img src={ele?.image} alt="..." />
                                        </div>
                                        <div className='whyworkdivmaintxt'>
                                            <p className='whyworkdivmaintxtpphhhdr'>{ele?.heading}</p>
                                            <p className='whyworkdivmaintxtpp'>
                                                {ele?.description}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section >
        </>
    )
}

export default Whywork
