import React from 'react'

const Legacymodernizationcontent = ({ legacyData }) => {
    return (
        <>
            <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>{legacyData && legacyData?.mainHeading}</h2>
                    <p className='hlthcaretetx_para_text'>
                    {legacyData && legacyData?.description}
                    </p>

              


                </div>
            </section>
        </>
    )
}

export default Legacymodernizationcontent
