import React from 'react'

const Excutivesearchcontent = ({ executiveSearchData }) => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          <h2 className='sec_heading'>{executiveSearchData && executiveSearchData?.mainHeading} </h2>

          <p className='hlthcaretetx_para_text'>
            {executiveSearchData && executiveSearchData?.description}
          </p>
        </div>
      </section>
    </>
  )
}

export default Excutivesearchcontent
