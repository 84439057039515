import React from 'react'

const Aimlcontent = ({ aiMlData }) => {
  return (
     <>
       <section className='SectorWeServer_sec'>
        <div className='cus_container'>
            <h2 className='sec_heading'>{aiMlData && aiMlData?.mainHeading}</h2>
          <p className='hlthcaretetx_para_text'>
          {aiMlData && aiMlData?.description}
          </p>

          <h2 className='ggpappadinbtm'>
          Here's how we make it happen :
          </h2>


        </div>
      </section>
     </>
  )
}

export default Aimlcontent
