import React from 'react'

const Engineeringcontent = ({ engineeringServiceData }) => {
  return (
    <section className='SectorWeServer_sec'>
      <div className='cus_container'>
        {/* <h2 className='sec_heading'>The Sector We Serve  Engineering Services</h2> */}
        <p className='hlthcaretetx_para_text'>
          {engineeringServiceData && engineeringServiceData?.description}
        </p>
        <h2 className='ggpappadinbtm'>
          {engineeringServiceData && engineeringServiceData?.mainHeading}
        </h2>
      </div>
    </section>
  )
}

export default Engineeringcontent
