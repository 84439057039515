import React from 'react'
// import DIGITALANALYTICS1 from "../../Images/DIGITALANALYTICS1.jpg"
const Digitalandanalyticsmain = ({ digitalAnalyticsData }) => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={digitalAnalyticsData && digitalAnalyticsData?.banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {digitalAnalyticsData && digitalAnalyticsData?.whiteHeading} <span> {digitalAnalyticsData && digitalAnalyticsData?.blueHeading}  </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Digitalandanalyticsmain
