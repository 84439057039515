import React from 'react'
// import FlexibleEngagementModels from "../../Images/FlexibleEngagementModels.jpeg";
import cennyaytimafe from "../../Images/cennyaytimafe.jpeg";
// import candiftatscreimg from "../../Images/candiftatscreimg.jpeg";
// import workforceplal from "../../Images/workforceplal.jpg";
const Mspprogramcontentmain = ({ mspData }) => {
    return (

        <>

            {/* <section className="softwaresevices_main" >
                <div className="cus_container">
       

                    <div className=''>
                        <div className='gebnerativeseraipoewerimgdivflx'>
                            <div className='gebnerativesericevesdivflx'>
                                <div className="gebnerativesericevesdivleft">

                                    <div className="softwaredivspclbgdivfirstleftbg">
                                        <h4 className="fullstxttsrrtppp">Centralized Vendor Management</h4>
                                        <p className="fullstxttsrrtpppshrtdexc">
                                        We work closely with clients to develop a customized vendor management strategy that is tailored to their specific needs, ensuring best talent in the industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="gebnerativesericevesdivleft">

                                    <div className="softwaredivspclbgdivfirstleftbg">
                                        <h4 className="fullstxttsrrtppp">Comprehensive Candidate Screening and Selection</h4>
                                        <p className="fullstxttsrrtpppshrtdexc">
                                        Our team of experienced recruiters uses a comprehensive screening and selection process to identify the best candidates for our clients' needs.
                                        </p>
                                    </div>
                                </div>

                                <div className="gebnerativesericevesdivright">

                                    <div className="softwaredivspclbgdivfirstleftbg">
                                        <h4 className="fullstxttsrrtppp">  Proactive Workforce Management</h4>
                                        <p className="fullstxttsrrtpppshrtdexc">
                                        Our MSP is designed to be proactive with workforce performance, provide real-time analytics, and offer actionable insights to help clients make informed decisions.
                                        </p>
                                    </div>
                                </div>

                                <div className="gebnerativesericevesdivright">

                                    <div className="softwaredivspclbgdivfirstleftbg">
                                        <h4 className="fullstxttsrrtppp">Flexible Engagement Models</h4>
                                        <p className="fullstxttsrrtpppshrtdexc">
                                        We offer flexible engagement models to meet our clients' specific needs with customized solutions that are tailored to their unique requirements.
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className='aipoewerimgmainmainimjhh'>
                                <div className='aipoewerimgmain'>
                                    <img src={cuuut} alt='...' />
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </section> */}

            <section className="softwaresevices_main" >
                <div className="cus_container">
                    {
                        mspData && mspData?.content?.length !== 0 && mspData?.content?.map((mspItem, index) => (
                            <div className="healthcares_divnewadd">
                                <div className="healthcares_div_left">
                                    <div className="">
                                        <h4 className="hlthcaretetx_heading_text"> {mspItem?.heading}</h4>
                                        <p className="hlthcaretetx_para_text">
                                            {mspItem?.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="healthcares_div_right">
                                    <div className="healthcares_div_imgmainalada">
                                        <img src={mspItem?.image} alt="..." />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>

        </>
    )
}

export default Mspprogramcontentmain
