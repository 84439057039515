import React from 'react'

const Consultingcontent = ({ businessData }) => {
    return (
        <>
            <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    <h2 className='sec_heading'>{businessData && businessData?.mainHeading}</h2>



                    <p className='hlthcaretetx_para_text'>
                        {
                            businessData && businessData?.description
                        }
                    </p>
                    <p className='hlthcaretetx_para_text'>
                        At Cygnus, we specialize in delivering comprehensive Business IT consulting services tailored to the unique needs of our clients. Our expertise spans across various critical areas, ensuring that our clients are equipped to navigate the complexities of modern IT environments.
                    </p>

                </div>
            </section>
        </>
    )
}

export default Consultingcontent
