import React from 'react'
// import Machinelearning from "../../Images/Machinelearning.png";
import TechnologyStaffing from "../../Images/TechnologyStaffing.jpg";
// import ScientificClinicalStaffing from "../../Images/ScientificClinicalStaffing.jpg";
// import ProfessionalStaffing from "../../Images/Professional Staffing.jpg";
// import IndustrySpecializedStaffing from "../../Images/IndustrySpecializedStaffing.jpg"
const ContingentStaffingcontentmain = ({ contigentData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            contigentData && contigentData?.content?.length !== 0 && contigentData?.content?.map((contigentItem, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text"> {contigentItem?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                      {contigentItem?.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_img">
                    <img src={contigentItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className=''>
            <h4 className="hlthcaretetx_heading_text">{contigentData && contigentData?.conslutionHeading}</h4>
            <p className="hlthcaretetx_para_text">
              {contigentData && contigentData?.conslutionDescription}
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContingentStaffingcontentmain
