import React from 'react'
import workimhgfrst from "../../Images/Cygnus Pictures/Career page/amy-hirschi-K0c8ko3e6AA-unsplash.jpg";
import imgright from "../../Images/Cygnus Pictures/Career page/imgright.jpeg"
const workwith = ({ careerData }) => {
    const { heading, subHeading, careerBaner } = careerData;

    return (
        <>
            <section className='workmainsection'>
                <div className='cus_container'>
                    <div className=''>
                        <h4 className='worlttstxthhh'>{heading}</h4>
                        <p className='jiointxtdtnamirxrp'>
                            {subHeading}
                        </p>
                    </div>

                    <div className='workimgfrstrrimgrightdivflx'>
                        <div className='workimgfrstr'>
                            <img src={careerBaner} alt='...' />
                        </div>

                        <div className='workimgfrstrimgright'>
                            <img src={imgright} alt='...' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default workwith
