import React from 'react'
// import artificialintelliengnce from "../../Images/artificialintelliengnce.jpg"
const Aimlmain = ({ aiMlData }) => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={aiMlData && aiMlData?.banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {aiMlData && aiMlData?.whiteHeading} <span> {aiMlData && aiMlData?.blueHeading}  </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Aimlmain
