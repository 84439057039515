import React from 'react'
// import support from "../../Images/support.jpg";
// import legacybanner from "../../Images/legacybanner.jpg";
// import CustomizationOptimization from "../../Images/CustomizationOptimization.jpg";
// import intigration from "../../Images/intigration.png"
const Sapandoraclemaincontent = ({ sapAndOracleData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            sapAndOracleData && sapAndOracleData?.content?.length !== 0 && sapAndOracleData?.content?.map((sapOracleItem, index) => (
              <div className="healthcares_divnewadd">
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text">{sapOracleItem?.heading} </h4>
                    <p className="hlthcaretetx_para_text">
                      {sapOracleItem?.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_imgmainalada">
                    <img src={sapOracleItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default Sapandoraclemaincontent
