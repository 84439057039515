import React, { useEffect, useState } from 'react'
// import indiausa from "../../src/Images/indiausa.png";
// import india from "../../src/Images/india.png";
// import INNOVATION from "../../src/Images/Cygnus Pictures/Homepage/INNOVATIONwhite.png"
// import { NavLink } from 'react-router-dom';
import animation from "../Images/Video/animation.mp4"
import HttpClient from '../utils/HttpClient'

const Footer = () => {
  const [footerData, setFooterData] = useState({});

  // >>>>Fetch Footer Data<<<<
  const fetchFooterData = async () => {
    const res = await HttpClient.requestData("footer", "GET");
    if (res && res?.status) {
      setFooterData(res?.data)
    } else {
      setFooterData({});
    }
  }

  useEffect(() => {
    fetchFooterData();
  }, []);
  return (
    <section className='footersectionmain'>
      <div className='cus_container'>
        <div className=''>
          <p className='ledingtthewaytxtppp'>{footerData && footerData?.heading}</p>
        </div>
        <div className='footerdivmainflx'>
          <div className='footerdivmainleft'>


            <div className=''>
              <p className='phoneicontxtppp'>
                <span>
                  <i class="fa-solid fa-phone"></i>
                </span>
                +1 {footerData && footerData?.phoneNo}
              </p>
            </div>
            <div className=''>
              <p className='phoneicontxtppp'>
                <span>
                  <i class="fa-solid fa-m"></i>
                </span>
                {footerData && footerData?.email}
              </p>
            </div>
            {/* 
            <div className=''>
              <p className='phoneicontxtppp'>
                <span>
                <i class="fa-solid fa-phone"></i>
                </span>
                10:30 to 7:00

              </p>
            </div> */}
            <div className='uniytetstdsttraimgdoivmain'>
              <div className='uniytetstdsttraimgdoiv'>
                <p className='unitexttxxttppp'>
                  {footerData && footerData?.address1 && typeof footerData.address1 === 'string'
                    ? footerData.address1.substr(0, 13)
                    : ''}
                </p>
                <div className='indiausaimggsi'>
                  <img src={footerData && footerData?.address1Logo} alt='...' />
                </div>
              </div>
              <div className=''>
                <p className='Highwaytxttppp'>
                  {footerData && footerData?.address1 && typeof footerData.address1 === 'string'
                    ? footerData.address1.substr(14)
                    : ''}
                </p>
              </div>
            </div>

            <div className='uniytetstdsttraimgdoivmain'>
              <div className='uniytetstdsttraimgdoiv'>
                <p className='unitexttxxttppp'>
                  {footerData && footerData?.address2 && typeof footerData.address2 === 'string'
                    ? footerData.address2.substr(0, 5)
                    : ''}
                </p>
                <div className='indiausaimggsi'>
                  <img src={footerData && footerData?.address2Logo} alt='...' />
                </div>
              </div>
              <div className=''>
                <p className='Highwaytxttppp'>
                  {footerData && footerData?.address2 && typeof footerData.address2 === 'string'
                    ? footerData.address2.substr(6)
                    : ''}
                </p>
              </div>
            </div>
          </div>
          <div className='footerdivmainright'>

            <div className='footerdivmainrightimg'>
              {/* <img src={footerData && footerData?.image} alt='...' /> */}
              <video key={animation} autoPlay loop muted preload="auto" playsInline>
                <source src={animation} type="video/mp4" />
              </video>

            </div>

            {/* <ul className='gfooterdivlinkul'>
              <li className='gfooterdivlinkli'>
                <NavLink className="gfooterdivlinklinvlnk" to="/workmain">Gen AI</NavLink>
              </li>
              <li>
                <NavLink className="gfooterdivlinklinvlnk" to="/contact-us">Software Sevices</NavLink>
              </li>
              <li>
                <NavLink className="gfooterdivlinklinvlnk" to="/storymain">Industrial Solution</NavLink>
              </li>
              <li>
                <NavLink className="gfooterdivlinklinvlnk" to="/">Engineering Services</NavLink>
              </li>
              <li>
                <NavLink className="gfooterdivlinklinvlnk" to="/">Consulting</NavLink>
              </li>
              <li>
                <NavLink className="gfooterdivlinklinvlnk" to="/">MSP Program</NavLink>
              </li>
            </ul> */}
          </div>
        </div>

        <div className=''>
          <p className='cyttgstfoittetxtpp'>@ 2024 Cygnus. All Rights Reserved</p>
        </div>
      </div>
    </section>
  )
}

export default Footer