import React, { useEffect, useState } from 'react'
import Engineeringmain from './Engineeringmain'
import Engineeringcontent from './Engineeringcontent'
import Engineeringcontentmain from './Engineeringcontentmain'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Engineeringindex = () => {
  const [engineeringServiceData, setEngineeringServiceData] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  // >>>>Fetch Engineering Services Data<<<<
  const fetchEngineeringServicesData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("services", "POST", {
      title: 'Engineering Services'
    });
    if (res && res?.status) {
      setIsLoading(false)
      setEngineeringServiceData(res?.data)
    } else {
      setIsLoading(false);
      setEngineeringServiceData({});
    }
  }

  useEffect(() => {
    fetchEngineeringServicesData();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Engineeringmain engineeringServiceData={engineeringServiceData} />
      <Engineeringcontent engineeringServiceData={engineeringServiceData} />
      <Engineeringcontentmain engineeringServiceData={engineeringServiceData} />
    </>
  )
}

export default Engineeringindex

