import React from 'react'

const Pharmacontent = ({ pharmaData }) => {
  return (
    <section className='SectorWeServer_sec'>
      <div className='cus_container'>

        <p className='hlthcaretetx_para_text'>
          {pharmaData?.description}
        </p>
      </div>
    </section>
  )
}

export default Pharmacontent
