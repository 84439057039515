import React from 'react'
// import sap from "../../Images/sap.jpg"
const Sapandoraclemain = ({ sapAndOracleData }) => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={sapAndOracleData && sapAndOracleData?.banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {sapAndOracleData && sapAndOracleData?.whiteHeading} <span> {sapAndOracleData && sapAndOracleData?.blueHeading} </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Sapandoraclemain
