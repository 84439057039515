import React from 'react';
import img1 from "../../Images/Cygnus Pictures/Homepage/experinence.png";
import img2 from "../../Images/Cygnus Pictures/file (1).png";
import img3 from "../../Images/Cygnus Pictures/WhatsApp Image 2024-07-04 at 15.48.30_9c3e438c-Photoroom.png";
import img4 from "../../Images/Cygnus Pictures/file (2).png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import experince from "../../Images/Cygnus Pictures/Homepage/experince.png";
import innovationnnn from "../../Images/Cygnus Pictures/Homepage/innovationnnn.png";
import achievementtt from "../../Images/Cygnus Pictures/Homepage/achievementtt.png";
import awardsimg from "../../Images/Cygnus Pictures/Homepage/awardsimg.png";
import img11 from "../../Images/Cygnus Pictures/img1.png";
import img22 from "../../Images/Cygnus Pictures/img2.png";
import img33 from "../../Images/Cygnus Pictures/img3.jpeg";
import img44 from "../../Images/Cygnus Pictures/img4.jpeg";
import img55 from "../../Images/Cygnus Pictures/img5.jpeg";

import img66 from "../../Images/wipro.jpeg";
import img77 from "../../Images/capgemini.jpeg";
import img88 from "../../Images/hcl.jpeg";
import img99 from "../../Images/bmw.jpeg";
import certificationslogoimg from "../../Images/certificationslogoimg.jpeg"
const OurExperienceSlider = ({ homeBannerData }) => {
    const { section1, section2, section3, section4 } = homeBannerData;
    return (
        <>
            <section className='OurExperienceSlider_sec'>
                <div className='cus_container'>







                    {/* Section 1 */}
                    <div className='experenceallbgimg' id='experince'>
                        <div className='experenceallbgimgmain'>
                            <img src={section1?.image ? section1?.image : experince} alt='' />

                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>{section1?.heading}</h2>
                                <p className='sec_desctextppecp'>{section1?.description}</p>

                                <div className='row progsecaera'>
                                    {section1?.services && section1?.services?.length !== 0 &&
                                        section1?.services?.map((ele, ind) => {
                                            return (
                                                <div key={ind} className='col-lg-6 col-md-6 col-6 marfbf-22 '>
                                                    <h4 className='progressName'>{ele}</h4>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{ width: "70%" }}
                                                            aria-valuenow={70}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                {/* <div className='certtsiisfoimdivflx'>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img66} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img77} alt='...' />
                                    </div>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img88} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                    <img src={img99} alt='...' />

                                    </div>
                                 
                                </div> */}

                            </div>
                        </div>

                    </div>

                    {/* Section 2 */}
                    <div className='experenceallbgimg' id='innovstion'>
                        <div className='experenceallbgimgmain'>
                            <img src={section2?.image ? section2?.image : innovationnnn} alt='' />
                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>{section2?.heading}</h2>
                                <p className='sec_desctextppecp'>
                                    {section2?.description}
                                </p>
                                {/* <Link className='exploreMore_btn' to="/innovation">Explore More</Link> */}
                                <div className='row progsecaera'>
                                    {section2?.services && section2?.services?.length !== 0 &&
                                        section2?.services?.map((ele, ind) => {
                                            return (
                                                <div key={ind} className='col-lg-6 col-md-6 col-6 marfbf-22'>
                                                    <h4 className='progressName'>{ele}</h4>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {/* <div className='certtsiisfoimdivflx'>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img11} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img22} alt='...' />
                                    </div>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img33} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img44} alt='...' />

                                    </div>
                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img55} alt='...' />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* Section 3 */}
                    <div className='experenceallbgimg' id="achievement">
                        <div className='experenceallbgimgmain'>
                            <img src={section3?.image ? section3?.image : achievementtt} alt='' />
                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>{section3?.heading}</h2>
                                <p className='sec_desctextppecp'>{section3?.description}</p>

                                {/* <Link className='exploreMore_btn' to="/achievement">Explore More</Link> */}

                            </div>
                        </div>
                    </div>

                    {/* Section 4 */}
                    <div className='experenceallbgimg' id="award">
                        <div className='experenceallbgimgmain'>
                            <img src={awardsimg} alt='' />
                            <div className='maincontentwrap'>
                                <h2 className='sec_headingexpertxtpp'>{section4?.heading}</h2>
                                <p className='sec_desctextppecp'>{section4?.description}</p>

                                {/* <button className='exploreMore_btn'>Explore More</button> */}
                                <div className='certtsiisfoimdivflx'>

                                    {section4?.image && section4?.image?.length !== 0 &&
                                        section4?.image?.map((ele, ind) => {
                                            return (
                                                <div key={ind} className='certtsiisfoimdivimgmain'>
                                                    <img src={ele} alt='...' />
                                                </div>
                                            )
                                        })
                                    }

                                    {/* <div className='certtsiisfoimdivimgmain'>
                                        <img src={img11} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img22} alt='...' />
                                    </div>

                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img33} alt='...' />
                                    </div>


                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img44} alt='...' />

                                    </div>
                                    <div className='certtsiisfoimdivimgmain'>
                                        <img src={img55} alt='...' />
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>




                    {/* <Link className='OurExperience_Slider' style={{ backgroundColor: "#8e2d8e" }} to="/innovation"  >
                        <div className='content_wrap'>
                            <h2 className='sec_heading'>Innovation</h2>
                            <p className='sec_desctextppecp'>We believe that adaptability prompts to excellence and determined to techniques that carefully stick to imperatives of each client.</p>

                            <button className='exploreMore_btn'>Explore More</button>


                            <div className='row progsecaera'>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Digital Transformation</h4>
                                 
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Catalysts for Growth</h4>
                                 
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Business Intelligence</h4>
                             
                                </div>
                                <div className='col-lg-6 col-md-12 col-12 mb-4'>
                                    <h4 className='progressName'>Accelerated Innovation</h4>
                        
                                </div>
                            </div>
                        </div>

                        <div className='imagewrap_boxmain'>
                            <div className='imagewrap_box'>
                                <img src={img2} className='img-fluid' alt='...' />
                            </div>
                        </div>
                    </Link> */}




                    {/* <Link className='OurExperience_Slider' style={{ backgroundColor: "#ab6503" }} to="/achievement" >
                        <div className='content_wrap'>
                            <h2 className='sec_heading'>Achievements</h2>
                            <p className='sec_desctextppecp'>Implement an AI-driven inventory management system that uses machine learning algorithms to predict stock levels, optimize reorder points, and automate purchasing processes</p>

                            <button className='exploreMore_btn'>Explore More</button>


                        </div>

                        <div className='imagewrap_boxmain'>
                            <div className='imagewrap_box'>
                                <img src={img3} className='img-fluid' alt='...' />
                            </div>
                        </div>
                    </Link> */}




                    {/* <Link className='OurExperience_Slider' style={{ backgroundColor: "#26a98a" }} to="/awards" >
                        <div className='content_wrap'>
                            <h2 className='sec_heading'>Awards</h2>
                            <p className='sec_desctextppecp'>Implement an AI-driven inventory management system that uses machine learning algorithms to predict stock levels, optimize reorder points, and automate purchasing processes</p>

                            <button className='exploreMore_btn'>Explore More</button>


                        </div>

                        <div className='imagewrap_boxmain'>
                            <div className='imagewrap_box'>
                                <img src={img4} className='img-fluid' alt='...' />
                            </div>
                        </div>
                    </Link> */}




                    {/* <div className='listuldivmainkkk'>
                        <ul className='listuldivmainkkkul'>
                            <li className='listuldivmainkkkli'>
                                <a href='#experince' className='listuldivmainkkkliaaa'></a>
                            </li>
                            <li className='listuldivmainkkkli'>
                                <a href='#innovstion' className='listuldivmainkkkliaaa'></a>
                            </li>
                            <li className='listuldivmainkkkli'>
                                <a href='#achievement' className='listuldivmainkkkliaaa'></a>
                            </li>
                            <li className='listuldivmainkkkli'>
                                <a href='#award' className='listuldivmainkkkliaaa'></a>
                            </li>
                        </ul>
                    </div> */}




                </div>
            </section>
        </>
    )
}

export default OurExperienceSlider