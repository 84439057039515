
import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import { useNavigate } from 'react-router-dom';

import staffingservices from "../../Images/staffingservices.png";

const ServicesHome = ({ homeBannerData }) => {
    // const swiperRef = useRef(null);
    const navigate = useNavigate();
    const { Services } = homeBannerData;
    const swiperRef = useRef(null);

    const handleClick = (service) => {
        switch (service) {
            case "BUSINESS CONSULTING":
                navigate('/businessconsulting');
                break;
            case "STAFFING SERVICES":
                navigate('/staffingservice');
                break;
            case "CONTINGENT SERVICES":
                navigate('/contigentstuffing');
                break;
            case "EXECUTIVE SEARCH SERVICES":
                navigate('/excutivesearch');
                break;
            case "ENGINEERING SERVICES":
                navigate('/engineeringindex');
                break;
            case "DIGITAL & ANALYTICS SERVICES":
                navigate('/digitalanalytics');
                break;
            default:
                navigate('/')
        }
    };

    useEffect(() => {
        if (swiperRef.current && Services && Services.length > 0) {
            swiperRef.current.slideTo(0);
            swiperRef.current.update();
        }
    }, [Services]);


    return (
        <section className='ServicesHome_sec'>
            <div className='cus_container'>
                <h2 className='sec_heading'>Services</h2>

                <div className="healthcareswipper">
                    {/* <Swiper
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                            swiper.update(); 
                        }}
                        slidesPerView={3}
                        spaceBetween={30}
                        loop={true}
                        pagination={{ type: 'fraction' }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            400: { slidesPerView: 2 },
                            639: { slidesPerView: 3 },
                        }}
                        className='mySwiper'



                        // slidesPerView={3}
                        //     spaceBetween={30}
                        //     loop={true}              // Enable looping
                        //     pagination={{
                        //         type: 'fraction',
                        //     }}
                        //     navigation={true}
                        //     modules={[Pagination, Navigation]}

                        //     breakpoints={{
                        //         0: {
                        //             slidesPerView: 1,
                        //         },
                        //         400: {
                        //             slidesPerView: 2,
                        //         },
                        //         639: {
                        //             slidesPerView: 3,
                        //         },

                        //     }}
                        //     className='mySwiper'
                    >
                        {Services && Services.length > 0 && Services.map((ele, ind) => (
                            <SwiperSlide key={ind}>
                                <div onClick={() => handleClick(ele?.service)}>
                                    <div className="healthcare_divsld_img">
                                        <img src={ele?.image || staffingservices} className='img-fluid' alt='Service' />
                                    </div>
                                    <div className="servicetextarrowduvflx">
                                        <p className="slide_text_dtls_pp">
                                            {ele?.service}
                                        </p>
                                        <span className='arrowdivrignt'>
                                            <i className="fa-solid fa-right-long"></i>
                                        </span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper> */}

                    <Swiper
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        slidesPerView={3}
                        spaceBetween={30}
                        loop={true}
                        pagination={{ type: 'fraction' }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            400: { slidesPerView: 2 },
                            639: { slidesPerView: 3 },
                        }}
                        className="mySwiper"
                    >
                        {Services && Services.length > 0 &&
                            Services.map((ele, ind) => (
                                <SwiperSlide key={ind}>
                                    <div onClick={() => handleClick(ele?.service)}>
                                        <div className="healthcare_divsld_img">
                                            <img src={ele?.image || staffingservices} className="img-fluid" alt="Service" />
                                        </div>
                                        <div className="servicetextarrowduvflx">
                                            <p className="slide_text_dtls_pp">{ele?.service}</p>
                                            <span className="arrowdivrignt">
                                                <i className="fa-solid fa-right-long"></i>
                                            </span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default ServicesHome;
