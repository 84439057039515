import React from 'react'
// import Machinelearning from "../../Images/Machinelearning.png";
// import mobileapp from  "../../Images/mobileapp.png";
// import mobileapps from "../../Images/mobileapps.jpg";
// import fullstack from "../../Images/fullstack.jpg";
// import ITInfrastructure from "../../Images/ITInfrastructure.jpg"
const Mobileappsmaincontent = ({ mobileAppsData }) => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    {
                        mobileAppsData && mobileAppsData?.content?.length !== 0 && mobileAppsData?.content?.map((mobileAppsItem, index) => (
                            <div className="healthcares_divnewadd" key={index}>
                                <div className="healthcares_div_left">
                                    <div className="">
                                        <h4 className="hlthcaretetx_heading_text">{mobileAppsItem?.heading} </h4>
                                        <p className="hlthcaretetx_para_text">
                                            {mobileAppsItem?.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="healthcares_div_right">
                                    <div className="healthcares_div_imgmainalada">
                                        <img src={mobileAppsItem?.image} alt="..." />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
            <section className="softwaresevices_main">
                <div className="cus_container">
                    <div>
                        <p className="hlthcaretetx_para_text">
                            {mobileAppsData && mobileAppsData?.conslutionDescription}
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Mobileappsmaincontent
