import React, { useEffect, useState } from 'react'
import "../../Css/Team.css";
import Innerbanner from './Innerbanner';
import OurFounder from './OurFounder';
import ExpertTeam from './ExpertTeam';
import Advisioryboard from './Advisioryboard';
import GurudasComp from './GurudasComp';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';

const Myteam = () => {
    const [ourTeamData, setOurTeamData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // >>>>FetchOurTeam<<<<
    const fetchOurTeamData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("aboutTeam", "GET");
        if (res && res?.status) {
            setIsLoading(false)
            setOurTeamData(res?.data)
        } else {
            setIsLoading(false)
            setOurTeamData({})
        }
    }

    useEffect(() => {
        fetchOurTeamData()
    }, [])
    return (
        <>
            {isLoading && <Loader />}
            <Innerbanner ourTeamData={ourTeamData} />
            <OurFounder ourTeamData={ourTeamData} />

            {/* <GurudasComp ourTeamData={ourTeamData} /> */}
            <ExpertTeam ourTeamData={ourTeamData} />
            <Advisioryboard ourTeamData={ourTeamData} />
        </>
    )
}

export default Myteam
