import React from 'react'
// import Machinelearning from "../../Images/Machinelearning.png";
import aipoewerimg from "../../Images/aipoewerimg.png";
// import nlp from "../../Images/nlp.jpg";
// import consulting from "../../Images/consulting.png"
const Aimlmaincontent = ({ aiMlData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            aiMlData && aiMlData?.content?.length !== 0 && aiMlData?.content?.map((aiMlItem, index) => (
              <div className="healthcares_divnewadd">
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text">{aiMlItem?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                      {aiMlItem?.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_imgmainalada">
                    <img src={aiMlItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>

      <section className="softwaresevices_main">
        <div className="cus_container">
          <div className>

            <p className="hlthcaretetx_para_text">{aiMlData && aiMlData?.conslutionDescription}</p>
          </div>
        </div>
      </section>

    </>
  )
}

export default Aimlmaincontent
