import React, { useEffect, useState } from 'react'
import Mspprogrammain from './Mspprogrammain'
import Mspprogramcontent from './Mspprogramcontent'
import Mspprogramcontentmain from './Mspprogramcontentmain'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Mspprogramindex = () => {
  const [mspData, setMspData] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  // >>>>Fetch Msp Data<<<<
  const fetchMspData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("services", "POST", {
      title: 'Msp'
    });
    if (res && res?.status) {
      setIsLoading(false)
      setMspData(res?.data)
    } else {
      setIsLoading(false);
      setMspData({});
    }
  }

  useEffect(() => {
    fetchMspData();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Mspprogrammain mspData={mspData} />
      <Mspprogramcontent mspData={mspData} />
      <Mspprogramcontentmain mspData={mspData} />
    </>
  )
}

export default Mspprogramindex
