import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import johnandjhons from "../../Images/johnandjhons.png";
import mayoclinick from "../../Images/mayoclinick.png";
import google from "../../Images/google.png";
import bandn from "../../Images/bandn.png";
import bmw from "../../Images/bmw.jpeg";
import shaw from "../../Images/shaw.png";
import cdw from "../../Images/cdw.png";
import commure from "../../Images/commure.png";
import goldasnsachlogo from "../../Images/goldasnsachlogo.jpeg";
import schndr from "../../Images/schndr.png";
import rallauren from "../../Images/rallauren.png";
import harman from "../../Images/harman.png"
const ClientSays = ({ homeBannerData }) => {
    const { testimonial } = homeBannerData;
    return (
        <section className="clientsayasmain">
            <div className='cus_container'>
                <div className='clientsayasmaincontentdiv'>
                    <div className=''>
                        <h4 class="carrertxtteamhhh">Client Success Testimonials </h4>
                    </div>

                    <div className=''>
                        <div className="">

                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                navigation={true}
                                loop={true}
                                modules={[Navigation]}
                                className="mySwiper"
                            >
                                {testimonial && testimonial?.length !== 0 &&
                                    testimonial?.map((ele, ind) => {
                                        return (
                                            <SwiperSlide>
                                                <div className='employeuimgtextdivflxsld'>
                                                    <div className='climetsaysyimgdivmain'>
                                                        <div className='climetsaysyimgdivbgimhhj'>
                                                            <div className='climetsaysyimgdiv'>
                                                                <img src={ele?.logo ? ele?.logo : google} alt='...' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='clinetsttsaytsxtdiv'>
                                                        <p className='clinetsttsaytsxtdivpp'>
                                                            {`"${ele?.description}"`}
                                                        </p>
                                                        <p className='shtrittiyadaytxtpp'>
                                                            {ele?.heading}
                                                        </p>

                                                    </div>

                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }

                                {/*  */}

                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ClientSays
