import React, { useEffect } from 'react'
import Legacymodernizationmain from './Legacymodernizationmain'
import Legacymodernizationcontent from './Legacymodernizationcontent'
import Legacymodernizationmaincontent from './Legacymodernizationmaincontent'
import { useState } from 'react'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Legacymodernization = () => {
    const [legacyData, setLegacyData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch Legacy Modernisation Data<<<<
    const fetchLegacyModernisationData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("services", "POST", {
            title: 'Legacy Modernisation'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setLegacyData(res?.data)
        } else {
            setIsLoading(false);
            setLegacyData({});
        }
    }

    useEffect(() => {
        fetchLegacyModernisationData();
    }, []);
    return (
        <>
            {isLoading && <Loader />}
            <Legacymodernizationmain legacyData={legacyData} />
            <Legacymodernizationcontent legacyData={legacyData} />
            <Legacymodernizationmaincontent legacyData={legacyData} />
        </>
    )
}

export default Legacymodernization
