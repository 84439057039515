import React from 'react'

const Sapandoraclecontent = ({ sapAndOracleData }) => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          <h2 className='sec_heading'>{sapAndOracleData && sapAndOracleData?.mainHeading}</h2>
          <p className='hlthcaretetx_para_text'>
            {sapAndOracleData && sapAndOracleData?.description}
          </p>

          <h2 className='ggpappadinbtm'>
            Our services include :
          </h2>


        </div>
      </section>
    </>
  )
}

export default Sapandoraclecontent
