import React from 'react'

const Bfsicontent = ({bfsiData}) => {
  return (
    <section className='SectorWeServer_sec'>
                <div className='cus_container'>
                    
                    <p className='hlthcaretetx_para_text'>
                    {bfsiData?.description}
                    </p>
                    <p className='hlthcaretetx_para_text'>
                    In an industry where precision and trust are paramount, our comprehensive suite of services is meticulously designed to drive operational excellence and elevate client engagement. Explore how we can facilitate your organization’s success:
                    </p>

                </div>
            </section>
  )
}

export default Bfsicontent
