import React, { useEffect, useState } from 'react'
import Generativemain from "../GenerativeAi/Generativemain";
import Generativeaicontent from "../GenerativeAi/Generativeaicontent";
import Generativecontentmain from "../GenerativeAi/Generativecontentmain"
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';
const Generativeindex = () => {
    const [frameWorkData, setFrameWorkData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // >>>>FetchFrameWorkData<<<<
    const fetchFrameWorkData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("framework", "GET");
        if (res && res?.status) {
            setIsLoading(false)
            setFrameWorkData(res?.data)
        } else {
            setIsLoading(false)
            setFrameWorkData({})
        }
    }

    useEffect(() => {
        fetchFrameWorkData();
    }, [])
    return (
        <>
            {isLoading && <Loader />}
            <Generativemain frameWorkData={frameWorkData} />
            <Generativeaicontent frameWorkData={frameWorkData} />
            <Generativecontentmain frameWorkData={frameWorkData} />
        </>
    )
}

export default Generativeindex
