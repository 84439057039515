import React from 'react'

const Retailcontent = ({retailData}) => {
  return (
    <section className='SectorWeServer_sec'>
      <div className='cus_container'>

        <p className='hlthcaretetx_para_text'>
          {
            retailData && retailData?.description
          }
        </p>

        <p className='hlthcaretetx_para_text'>
          As specialists in retail consulting, our extensive range of services is designed to enhance operational performance and customer engagement. Explore how we can support your business:
        </p>


      </div>
    </section>
  )
}

export default Retailcontent
