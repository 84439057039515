import React from 'react'

const ContingentStaffingcontent = ({contigentData}) => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>
          {/* <h2 className='sec_heading'>Driving Innovation and Efficiency in a Digital World </h2> */}



          <p className='hlthcaretetx_para_text'>
          {contigentData && contigentData?.description}
          </p>
        

        </div>
      </section>
    </>
  )
}

export default ContingentStaffingcontent
