import React, { useEffect, useState } from 'react'
import Pharmacompliencemain from './Pharmacompliencemain'
import Pharmacompliencecontent from './Pharmacompliencecontent'
import Pharmacompliencemaincontent from './Pharmacompliencemaincontent'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Pharmacomplience = () => {
    const [pharmaComplienceData, setPharmaComplienceData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // >>>>Fetch Pharma Complience<<<<
    const fetchPharmaComplience = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("pharma-complainces", "GET");
        if (res && res?.status) {
            setIsLoading(false)
            setPharmaComplienceData(res?.data)
        } else {
            setIsLoading(false)
            setPharmaComplienceData({})
        }
    }

    useEffect(() => {
        fetchPharmaComplience()
    }, [])
    return (
        <>
            {isLoading && <Loader />}
            <Pharmacompliencemain pharmaComplienceData={pharmaComplienceData} />
            <Pharmacompliencecontent pharmaComplienceData={pharmaComplienceData} />
            <Pharmacompliencemaincontent pharmaComplienceData={pharmaComplienceData} />
        </>
    )
}

export default Pharmacomplience
