import React from 'react'
import Genaibanner from "../../Images/Genaibanner.jpeg"
const Generativemain = ({ frameWorkData }) => {
    return (
        <section className='industrialsolution_banner'>
            <div className='bgimage_'>
                <img src={frameWorkData?.[0]?.banner} className='img-fluid' alt='..' />
            </div>

            <div className='banner_wrap'>
                <h2 className='sec_headingbannertset'>{frameWorkData?.[0]?.whiteHeading}<span className='ai-span'>{frameWorkData?.[0]?.blueHeading}</span> </h2>
            </div>

        </section>
    )
}

export default Generativemain
