import React, { useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';

const ContactUs = () => {
    const initData = {
        name: "",
        email: "",
        companyName: "",
        message: "",
        country: ""
    }
    const [formData, setFormData] = useState(initData);
    const [isLoading, setIsLoading] = useState(false);

    // >>>>Handle Change Input<<<<
    const handleChangeInput = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'country') {
            if (checked) {
                setFormData({ ...formData, country: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    // >>>>Handle Submit<<<<
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, companyName, message, country } = formData;
        const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (name === "") {
            return toast.error("Please enter name...!");
        } else if (email === "") {
            return toast.error("Please enter email...!");
        } else if (!email.match(mailFormat)) {
            return toast.error("Please enter valid email...!");
        } else if (companyName === "") {
            return toast.error("Please enter company name...!");
        } else if (message === "") {
            return toast.error("Please write message...!");
        } else if (country === "") {
            return toast.error("Please select country...!");
        } else {
            const data = {
                name,
                email,
                companyName,
                message,
                country
            }
            // return
            setIsLoading(true)
            const res = await HttpClient.requestData("contact-us", "POST", data);
            if (res && res?.status) {
                setIsLoading(false)
                toast.success(res?.message || "Data submitted successfully.!");
                setFormData(initData)
            } else {
                setIsLoading(false)
                return toast.error(res?.message || "Something went wrong...!s")
            }
        }
    }

    return (
        <>
            <section className='contactus_sec'>
                {isLoading && <Loader />}
                <div className='bgcolor'></div>
                <div className='cus_container'>
                    <div className='left_content'>
                        <h2 className='sec_heading'>Contact Us</h2>
                        <p className='sec_desc'>Feel free to contact us any time, we will get back to you as soon as we can!</p>

                        <form onSubmit={handleSubmit}>
                            {/* Name */}
                            <div className="form-group">
                                <input
                                    style={{ color: "#000" }}
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Name"
                                    name='name'
                                    value={formData?.name}
                                    onChange={handleChangeInput}
                                />
                            </div>

                            {/* Email */}
                            <div className="form-group">
                                <input
                                    style={{ color: "#000" }}
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Email"
                                    name='email'
                                    value={formData?.email}
                                    onChange={handleChangeInput}
                                />
                            </div>

                            {/* Company Name */}
                            <div className="form-group">
                                <input
                                    style={{ color: "#000" }}
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Company Name"
                                    name='companyName'
                                    value={formData?.companyName}
                                    onChange={handleChangeInput}
                                />
                            </div>

                            {/* Message */}
                            <div className="form-group">
                                <input
                                    style={{ color: "#000" }}
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Message"
                                    name='message'
                                    value={formData?.message}
                                    onChange={handleChangeInput}
                                />
                            </div>

                            {/* Country Checkbox */}
                            <div className='row mb-5 mt-4'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            name='country'
                                            value="India"
                                            checked={formData?.country === "India"}
                                            onChange={handleChangeInput}
                                            className="form-check-input"
                                            id="indiacheck"
                                        />
                                        <label className="form-check-label" htmlFor="indiacheck">
                                            India
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            name='country'
                                            value="United States"
                                            checked={formData?.country === "United States"}
                                            onChange={handleChangeInput}
                                            className="form-check-input"
                                            id="unitedcheck"
                                        />
                                        <label className="form-check-label" htmlFor="unitedcheck">
                                            United States
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Button */}
                            <button type="submit" className="btn Submit_btn">
                                Submit
                            </button>

                        </form>

                    </div>

                    <div className='right_content'>
                        <h4 className='head'>Info</h4>
                        <div className='info_box'>
                            <i className="fa-solid fa-envelope"></i>
                            <span>info@cygnuspro.com</span>
                        </div>
                        <div className='info_box'>
                            <i className="fa-solid fa-phone"></i>
                            <span>+1 (732) 485-0000</span>
                        </div>

                        <div className='info_box'>
                            <i className="fa-solid fa-building"></i>
                            <div>
                                <div className='d-flex'>
                                    <p className='text1'>United States </p>
                                    <p className='text2'>3490 US Highway 1, #4, Princeton, New Jersey 08540</p>
                                </div>
                                <div className='d-flex'>
                                    <p className='text1'>India</p>
                                    <p className='text2'>Suite C, First Floor, Linus Opp. to U.S. Consulate Begumpet, Hyderabad, Telangana 500016</p>
                                </div>
                            </div>
                        </div>

                        {/* <div className='info_box'>
                            <i className="fa-solid fa-phone"></i>
                            <span>10:30 to 7:00</span>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs