import React, { useEffect, useState } from 'react'
import Bfsimain from './Bfsimain'
import Bfsicontent from './Bfsicontent'
import Bfsicontentmain from './Bfsicontentmain'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Bfsi = () => {
  const [bfsiData, setBfsiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  // >>>>Fetch BFSI Data<<<<
  const fetchBFSIData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("solution", "POST", {
      title: 'BFSI'
    });
    if (res && res?.status) {
      setIsLoading(false)
      setBfsiData(res?.data)
    } else {
      setIsLoading(false);
      setBfsiData({});
    }
  }

  useEffect(() => {
    fetchBFSIData();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Bfsimain bfsiData={bfsiData} />
      <Bfsicontent bfsiData={bfsiData} />
      <Bfsicontentmain bfsiData={bfsiData} />
    </>
  )
}

export default Bfsi
