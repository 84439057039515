import React from 'react'
// import StrategicMarket from "../../Images/StrategicMarket.jpg";
import UnmatchedTechnical from "../../Images/UnmatchedTechnical.jpg";
// import EfficientCompliance from "../../Images/EfficientCompliance.jpg";
// import RigorousAdherence from "../../Images/RigorousAdherence.jpg"
const Engineeringcontentmain = ({ engineeringServiceData }) => {
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">
                    {
                        engineeringServiceData && engineeringServiceData?.content?.length !== 0 && engineeringServiceData?.content?.map((engineeringItem, index) => (
                            <div className="healthcares_divnewadd">
                                <div className="healthcares_div_left">
                                    <div className="">
                                        <h4 className="hlthcaretetx_heading_text">{engineeringItem?.heading}</h4>
                                        <p className="hlthcaretetx_para_text">
                                            {engineeringItem?.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="healthcares_div_right">
                                    <div className="healthcares_div_imgmainalada">
                                        <img src={engineeringItem?.image} alt="..." />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>

            <section className="softwaresevices_main" >
                <div className="cus_container">
                    <div className=''>
                        <h4 className="hlthcaretetx_heading_text">{engineeringServiceData && engineeringServiceData?.conslutionHeading}</h4>
                        <p className="hlthcaretetx_para_text">
                            {engineeringServiceData && engineeringServiceData?.conslutionDescription}
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Engineeringcontentmain
