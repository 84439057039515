import React, { useEffect, useState } from 'react'
import Cybersecuritymain from './Cybersecuritymain'
import Cybersecuritycontent from './Cybersecuritycontent'
import Cybersecuritymaincontent from './Cybersecuritymaincontent'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Cybersecurity = () => {
    const [cyberSecData, setCyberSecData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // >>>>Fetch Cyber Security Data<<<<
    const fetchCyberSecData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("cyber-security", "GET");
        if (res && res?.status) {
            setIsLoading(false)
            setCyberSecData(res?.data)
        } else {
            setIsLoading(false)
            setCyberSecData({})
        }
    }

    useEffect(() => {
        fetchCyberSecData();
    }, [])
    return (
        <>
            {isLoading && <Loader />}
            <Cybersecuritymain cyberSecData={cyberSecData} />
            <Cybersecuritycontent cyberSecData={cyberSecData} />
            <Cybersecuritymaincontent cyberSecData={cyberSecData} />
        </>
    )
}

export default Cybersecurity
