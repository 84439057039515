import React from 'react'
// import retailasoftware from "../../Images/retailasoftware.jpg";
// import ecommerceintegration from "../../Images/ecommerceintegration.jpg";
// import pos from "../../Images/pos.jpg";
// import sorfraerrttrstysenfgyy from "../../Images/sorfraerrttrstysenfgyy.jpg";
// import uiusyhystetdtesuppiort from "../../Images/uiusyhystetdtesuppiort.jpg";
// import ertysyyuyyy from "../../Images/ertysyyuyyy.jpg";
import sippriisolution from "../../Images/sippriisolution.jpg"
const Retailcontentmain = ({ retailData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            retailData && retailData?.content?.length !== 0 && retailData?.content?.map((retailItem, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text">{retailItem?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                      {
                        retailItem?.description
                      }
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_img">
                    <img src={retailItem?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>



      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className='choosedivflx'>
            <div className='choosedivleft'>
              <h4 className="hlthcaretetx_heading_text">Why Choose Cygnus Professionals?</h4>
              <p className="hlthcaretetx_para_text_new">
                <span> 	Tailored Solutions : </span> We understand that each organization has its own unique challenges. By taking the time to connect with you and comprehend your specific needs, we develop customized strategies that deliver measurable results.
              </p>

              <p className="hlthcaretetx_para_text_new">
                <span>		Deep Industry Expertise : </span> Our team comprises seasoned professionals with extensive experience in the retail sector. This wealth of knowledge allows us to provide insightful guidance and innovative solutions that keep you ahead of the competition.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> 	Comprehensive Support :  </span> From initial consultation through to implementation and ongoing maintenance, we offer a fully integrated approach. Our commitment to your success means we are with you at every stage of your journey.
              </p>


              <p className="hlthcaretetx_para_text_new">
                <span> Quality-Driven Results : </span> Our dedication to excellence ensures that we prioritize quality in all our services. You can trust us to deliver outcomes that not only meet but exceed your expectations.
              </p>

            </div>
            <div className='choosedivright'>
              <div className='choosedivrightimgmain'>
                <img src={sippriisolution} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="softwaresevices_main" >
        <div className="cus_container">
          <div className=''>
            <h4 className="hlthcaretetx_heading_text">{retailData && retailData?.endHeading}</h4>
            <p className="hlthcaretetx_para_text">
              {
                retailData && retailData?.endDescription
              }
            </p>
          </div>
        </div>
      </section>

    </>
  )
}

export default Retailcontentmain
