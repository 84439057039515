import React, { useEffect, useState } from 'react'
import Retailmain from './Retailmain'
import Retailcontent from './Retailcontent'
import Retailcontentmain from './Retailcontentmain'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Retail = () => {
  const [retailData, setRetailData] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  // >>>>Fetch Retail Data<<<<
  const fetchRetailData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("solution", "POST", {
      title: 'Retail'
    });
    if (res && res?.status) {
      setIsLoading(false)
      setRetailData(res?.data)
    } else {
      setIsLoading(false);
      setRetailData({});
    }
  }

  useEffect(() => {
    fetchRetailData();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Retailmain retailData={retailData} />
      <Retailcontent retailData={retailData} />
      <Retailcontentmain retailData={retailData} />
    </>
  )
}

export default Retail
