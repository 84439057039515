import React, { useEffect, useState } from 'react'
import Cloudtechnologiesmain from './Cloudtechnologiesmain'
import Cloudtechnologiescontent from './Cloudtechnologiescontent'
import Cloudtechnologiesmaincontent from './Cloudtechnologiesmaincontent'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Cloudtechnologies = () => {
    const [cloudTechnologiesData, setCloudTechnologiesData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch Cloud Technologies Data<<<<
    const fetchCloudTechnologiesData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("services", "POST", {
            title: 'Cloud Technologies'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setCloudTechnologiesData(res?.data)
        } else {
            setIsLoading(false);
            setCloudTechnologiesData({});
        }
    }

    useEffect(() => {
        fetchCloudTechnologiesData();
    }, []);
    return (
        <>
            {isLoading && <Loader />}
            <Cloudtechnologiesmain cloudTechnologiesData={cloudTechnologiesData} />
            <Cloudtechnologiescontent cloudTechnologiesData={cloudTechnologiesData} />
            <Cloudtechnologiesmaincontent cloudTechnologiesData={cloudTechnologiesData} />
        </>
    )
}

export default Cloudtechnologies
