import React from 'react'

const Digitalandanalyticscontent = ({ digitalAnalyticsData }) => {
  return (
    <>
      <section className='SectorWeServer_sec'>
        <div className='cus_container'>

          <p className='hlthcaretetx_para_text'>
            {digitalAnalyticsData && digitalAnalyticsData?.description}
          </p>

          <h2 className='ggpappadinbtm'>

            {digitalAnalyticsData && digitalAnalyticsData?.mainHeading}
          </h2>

        </div>
      </section>
    </>
  )
}

export default Digitalandanalyticscontent
