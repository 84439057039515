import React from 'react'
import Machinelearning from "../../Images/Machinelearning.png";
import PracticeManagement from "../../Images/PracticeManagement.jpg";
import Ehr from "../../Images/Ehr.jpg";
import HospitalManagement from "../../Images/HospitalManagement.png";
import bspoke from "../../Images/bspoke.jpg";
import clicnical from "../../Images/clicnical.png";
import PharmaManufacturing from "../../Images/PharmaManufacturing.jpg";
import PharmaDistribution from "../../Images/PharmaDistribution.jpg"
const Pharmacompliencemaincontent = ({ pharmaComplienceData }) => {
    const { content, endDescription } = pharmaComplienceData;
    console.log("sd54g65d1", content)
    return (
        <>
            <section className="softwaresevices_main" >
                <div className="cus_container">

                    {content && content?.length !== 0 &&
                        content?.map((ele) => {
                            return (
                                <div key={ele?._id} className="healthcares_divnewadd">
                                    <div className="healthcares_div_left">
                                        <div className="">
                                            <h4 className="hlthcaretetx_heading_text">{ele?.heading}</h4>
                                            <p className="hlthcaretetx_para_text">
                                                {ele?.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="healthcares_div_right">
                                        <div className="healthcares_div_imgmainalada">
                                            <img src={ele?.image} alt="..." />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </section>
            <section className="softwaresevices_main">
                <div className="cus_container">
                    <div>
                        <p className="hlthcaretetx_para_text">
                            {endDescription}
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pharmacompliencemaincontent
