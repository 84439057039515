import React from 'react'
// import mobileappsbanner from "../../Images/mobileappsbanner.jpg"
const Mobileappsmain = ({mobileAppsData}) => {
  return (
      <>
          <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={mobileAppsData && mobileAppsData?.banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {mobileAppsData && mobileAppsData?.whiteHeading} <span> {mobileAppsData && mobileAppsData?.blueHeading} </span> </h2>
        </div>
      </section>
      </>
  )
}

export default Mobileappsmain
