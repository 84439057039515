import React from 'react'

// import img1 from "../../Images/Cygnus Pictures/Industrial Solutions/pina-messina-kfJkpeI6Lgc-unsplash.jpg";
// import img2 from "../../Images/Cygnus Pictures/Industrial Solutions/thisisengineering-mvbtVeRVJzg-unsplash.jpg";
// import img3 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172342.png";
// import img4 from "../../Images/Cygnus Pictures/Industrial Solutions/Screenshot 2024-07-11 172510.png";
const Pharmacontentmain = ({ pharmaData }) => {
  return (
    <>
      <section className="softwaresevices_main" >
        <div className="cus_container">
          {
            pharmaData && pharmaData?.content?.length !== 0 && pharmaData?.content?.map((pharmaContent, index) => (
              <div className="healthcares_divnewadd" key={index}>
                <div className="healthcares_div_left">
                  <div className="">
                    <h4 className="hlthcaretetx_heading_text">{pharmaContent?.heading}</h4>
                    <p className="hlthcaretetx_para_text">
                      {pharmaContent?.description}
                    </p>
                  </div>
                </div>
                <div className="healthcares_div_right">
                  <div className="healthcares_div_img">
                    <img src={pharmaContent?.image} alt="..." />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default Pharmacontentmain
