import React, { useEffect, useState } from 'react'
import HomeBanner from './HomeBanner'
import OurExperienceSlider from './OurExperienceSlider'
import ServicesHome from './ServicesHome'
import HomeCareer from './HomeCareer'
import ClientSays from '../Work/ClientSays'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const Index = () => {
    const [homeBannerData, setHomeBannerData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // >>>>Fetch Home Data<<<<
    const fetchHomeData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("home", "GET");
        if (res && res?.status) {
            setIsLoading(false)
            setHomeBannerData(res?.data)
        } else {
            setIsLoading(false);
            setHomeBannerData({})
        }
    }

    useEffect(() => {
        fetchHomeData()
    }, [])
    return (
        <>
            {isLoading && <Loader />}
            <HomeBanner homeBannerData={homeBannerData} />
            <OurExperienceSlider homeBannerData={homeBannerData} />

            <ServicesHome homeBannerData={homeBannerData} />



            <ClientSays homeBannerData={homeBannerData} />

            <HomeCareer homeBannerData={homeBannerData} />
        </>
    )
}

export default Index