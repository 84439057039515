// import React, { useState } from 'react'
// import "../../Css/Work.css";
// import Workwith from "./Workwith"
// import Whywork from './Whywork';
// import Carreropertunity from './Carreropertunity';
// // import ClientSays from './ClientSays';
// import HttpClient from '../../utils/HttpClient';

// const workmain = () => {
//   const [careerData, setCareerData] = useState({});

//   // >>>>FetchCareerData<<<<
//   const fetchCareerData = async () => {
//     const res = await HttpClient.requestData("career", "GET");
//     if (res && res?.status) {
//       setCareerData(res?.data)
//     }
//   }
//   return (
//     <>
//       <Workwith />
//       <Whywork />
//       <Carreropertunity />
//       {/* <ClientSays/> */}
//     </>
//   )
// }

// export default workmain

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

import React, { useEffect, useState } from 'react';
import "../../Css/Work.css";
import Workwith from "./Workwith";
import Whywork from './Whywork';
import Carreropertunity from './Carreropertunity';
// import ClientSays from './ClientSays';
import HttpClient from '../../utils/HttpClient';
import Loader from '../../Loader/Loader';

const Workmain = () => {
  const [careerData, setCareerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // >>>>FetchCareerData<<<<
  const fetchCareerData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("career", "GET");
    if (res && res?.status) {
      setIsLoading(false)
      setCareerData(res?.data);
    } else {
      setIsLoading(false)
      setCareerData({});
    }
  };

  // Call the fetch function when the component mounts
  useEffect(() => {
    fetchCareerData();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Workwith careerData={careerData} />
      <Whywork careerData={careerData} />
      <Carreropertunity careerData={careerData} />
      {/* <ClientSays/> */}
    </>
  );
}

export default Workmain;
