import React from 'react'
// import TechnologyStaffing from "../../Images/TechnologyStaffing.jpg"
const Bfsimain = ({ bfsiData }) => {
  console.log('bfsiData from banner: ', bfsiData);

  return (
    <section className='industrialsolution_banner'>
      <div className='bgimage_'>
        <img src={bfsiData?.banner} className='img-fluid' alt='..' />
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {bfsiData?.whiteHeading} </h2>
        </div>

      </div>

    </section>
  )
}

export default Bfsimain
