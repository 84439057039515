import React from 'react'
// import staffingservices from "../../Images/staffingservices.png"
const Staffingmain = ({ staffingServicesData }) => {
  return (
    <>
      <section className='industrialsolution_banner'>
        <div className='bgimage_'>
          <img src={staffingServicesData && staffingServicesData?.banner} className='img-fluid' alt='..' />
        </div>
        <div className='banner_wrap'>
          <h2 className='sec_headingbannertset'> {staffingServicesData && staffingServicesData?.whiteHeading} <span> {staffingServicesData && staffingServicesData?.blueHeading}  </span> </h2>
        </div>
      </section>
    </>
  )
}

export default Staffingmain
