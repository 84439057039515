import React from 'react'
const Innerbanner = ({ ourTeamData }) => {
    const { whiteHeading, blueHeading, banner } = ourTeamData;
    return (
        <>

            <section className='industrialsolution_banner'>
                <div className='bgimage_'>
                    <img src={banner} className='img-fluid' alt='..' />
                </div>
                <div className='banner_wrap'>
                    <h2 className='sec_headingbannertset'> {whiteHeading} <span className='team-span'>{blueHeading}</span>  </h2>
                </div>
            </section>
        </>
    )
}

export default Innerbanner
