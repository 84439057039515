import React from 'react'
const OurFounder = ({ ourTeamData }) => {
    const { founderData } = ourTeamData
    return (
        <>
            <section className='ourfounder'>
                <div className='cus_container'>
                    <div className='founderimgdivmain'>
                        <p className='foundertxttpp'>
                            Our Founder
                        </p>
                    </div>
                    {founderData && founderData?.length !== 0 &&
                        founderData?.map((ele) => {
                            return (
                                <div key={ele?._id} className='ourfounderdivflx'>
                                    <div className='founderimgdivmain mb-4'>

                                        <div className='founderimgdiv'>

                                            <img src={ele?.image} alt='...' />
                                        </div>
                                        <p className='Vemtetxtppp'>
                                            {ele?.name}
                                        </p>
                                        <p className='founndtethhtstp'>
                                            {ele?.designation}
                                        </p>
                                    </div>

                                    <div className='foundertext'>
                                        <p className='foundertextpp'>
                                            {ele?.description}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </>
    )
}

export default OurFounder
