import React, { useEffect, useState } from 'react'
import Consultingmain from './Consultingmain'
import Consultingcontent from './Consultingcontent'
import Consultingcontentmain from './Consultingcontentmain'
import HttpClient from '../../utils/HttpClient'
import Loader from '../../Loader/Loader'

const BusinessConsulting = () => {
    const [businessData, setBusinessData] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    // >>>>Fetch Business Consulting Data<<<<
    const fetchBusinessConsultingData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("services", "POST", {
            title: 'Business Consulting'
        });
        if (res && res?.status) {
            setIsLoading(false)
            setBusinessData(res?.data)
        } else {
            setIsLoading(false);
            setBusinessData({});
        }
    }

    useEffect(() => {
        fetchBusinessConsultingData();
    }, []);
    return (
        <>
            {isLoading && <Loader />}
            <Consultingmain businessData={businessData} />
            <Consultingcontent businessData={businessData} />
            <Consultingcontentmain businessData={businessData} />
        </>
    )
}

export default BusinessConsulting
